import '../Authorization/common.module.scss';
import { useGoogleCaptcha } from '../../hooks/google-captcha';
import authApi from 'api/auth';
import { actionCreator } from 'api/utils';
import { isAxiosError } from 'axios';
import { LoginButton,
  LoginHeading,
  LoginInput,
  LoginParagraph } from 'components';
import { Svgicon } from 'components/Svgicon/Svgicon';
import { SignUpActivationContext } from 'contexts';
import { type ForgotPasswordPayload } from 'pages/Authorization/ForgotPassword/ForgotPassword';
import { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RECAPTCHA } from 'resources/constants';
import { notificationsService } from 'services';
import { messages } from 'services/notifications';
import { type AppDispatch } from 'store';
import { globalActionTypes } from 'store/actions';
import { removeItemsFromStorage } from 'types';
import { isEmail } from 'validator';

export const ExpiredLink = () => {
  const [
    email,
    setEmail,
  ] = useState('');
  const [
    success,
    setSuccess,
  ] = useState(false);
    // @ts-expect-error state not typed :(
  const isLoading = useSelector((state) => state.global.loading) as boolean;
  const {
    setIsSignUpInContext,
  } = useContext(SignUpActivationContext);
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const {
    RecaptchaGoogle,
    executeRecaptchaV2,
    executeRecaptchaV3,
    isExecutingRecaptcha,
    setShowCaptchaV2,
  } = useGoogleCaptcha('login');

  const onSubmitOneTimeLink = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!isEmail(email)) {
      notificationsService.error(messages.wrongEmail);
      return;
    }

    dispatch(actionCreator(globalActionTypes.LOADING, true));

    try {
      const recaptchaV2Token = await executeRecaptchaV2();
      if (recaptchaV2Token === null) {
        notificationsService.error('Please complete the captcha');
        return;
      }

      const recaptchaV3Token = await executeRecaptchaV3();
      const payload: ForgotPasswordPayload = {
        email,
        recaptchaV2Token,
        recaptchaV3Token,
      };

      await authApi.forgotPassword(payload);
      setSuccess(true);
    } catch (error) {
      if (isAxiosError(error)) {
        const errorMessage = error.response?.data.message;
        if (errorMessage === RECAPTCHA.ERRORS.SCORE_TOO_LOW) {
          setShowCaptchaV2(true);
          throw error;
        }
      }

      notificationsService.error(messages.standartError);
    } finally {
      dispatch(actionCreator(globalActionTypes.LOADING, false));
    }
  };

  const backToLoginButtonClick = () => {
    setIsSignUpInContext(false);
    navigate('/auth/login');
    removeItemsFromStorage([
      'isTokenExpired',
    ], sessionStorage);
  };

  const successComponent = () => <div className='flex flex-col gap-6'>
    <div className='flex flex-col gap-[0.5rem]'>
      <div className='flex items-center gap-[0.25rem]'>
        <Svgicon className='size-7' id='success' /><LoginHeading>Check your email</LoginHeading>
      </div>
      <LoginParagraph>
        We sent a one time link to your email.
      </LoginParagraph>
    </div>
    <LoginButton
      name='back-to-login-button'
      onClick={backToLoginButtonClick}
      text='Back to login'
      type='button'
    />
  </div>;

  if (success) {
    return successComponent();
  }

  return <div className='loginWrapper'>
    <div className='flex flex-col gap-6'>
      <div className='flex flex-col items-start gap-[0.5rem]'>
        <div className='flex items-center gap-[0.25rem]'>
          <Svgicon className='size-7' id='expired-link' /><LoginHeading>Your link has expired</LoginHeading>
        </div>
        <LoginParagraph>
          Press the button below to get a new link.
        </LoginParagraph>
      </div>
      <form onSubmit={onSubmitOneTimeLink}>
        <div className='inputsWrapper'>
          <LoginInput
            name='send-one-time-link-email-input'
            onChange={setEmail}
            placeholder='Email'
            type='email'
            value={email}
          />
          {RecaptchaGoogle}
        </div>
        <LoginButton
          isLoading={isLoading || isExecutingRecaptcha}
          name='forgot-password-button'
          text='Send one-time link'
          type='submit'
        />
      </form>
    </div>
  </div>;
};
