import { geoApi } from '../api/geo';
import { useEffect, useState } from 'react';

// Module-scoped cache so it's shared across hook usages
let ipData: { countryCode: string | null, } | null = null;

export const useIpLookup = () => {
  const [res, setRes] = useState<typeof ipData>(ipData);
  const [loading, setLoading] = useState(!ipData);
  const [error, setError] = useState<null | unknown>(null);

  useEffect(() => {
    // If already cached, skip fetching
    if (ipData) {
      return;
    }

    const fetchCountry = async () => {
      try {
        const response = await geoApi.getMyIpData();

        ipData = response;
        setRes({ countryCode: response.countryCode });
      } catch (error_: unknown) {
        setError(error_);
      } finally {
        setLoading(false);
      }
    };

    fetchCountry();
  }, []);

  const data = {
    countryCode: res?.countryCode,
  };

  return { data, error, loading };
};
