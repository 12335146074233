import Api from '.';

class GeoApi extends Api {
  public constructor () {
    super('/api/v1/geo');
  }

  public async getMyIpData () {
    const { data } = await this.get('/parse-ip');
    return data as { countryCode: string | null, };
  }
}

export const geoApi = new GeoApi();
