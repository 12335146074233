import { RECAPTCHA } from '../resources/constants';
import {useIpLookup} from './ip-lookup';
import { useCallback, useEffect, useRef, useState } from 'react';
import Recaptcha from 'react-google-recaptcha';

export const useGoogleCaptcha = (action: 'login' | 'forgotPassword') => {
  const { data: { countryCode }, loading } = useIpLookup();
  const [showCaptchaV2, setShowCaptchaV2] = useState(false);
  const captchaRef = useRef<Recaptcha>(null);
  const [isExecutingRecaptcha, setIsExecutingRecaptcha] = useState(false);

  const checkIfChina = () => !loading && countryCode?.toLowerCase() === 'cn';

  useEffect(() => {
    if (checkIfChina()) {
      return () => {};
    }

    // Load reCAPTCHA script
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA.V3_SITE_KEY}`;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const executeRecaptcha = useCallback(async () => {
    setIsExecutingRecaptcha(true);
    try {
      await window.grecaptcha?.ready(() => {});
      return await window.grecaptcha?.execute(RECAPTCHA.V3_SITE_KEY, { action });
    } finally {
      setIsExecutingRecaptcha(false);
    }
  }, []);

  const executeRecaptchaV3 = async () => {
    if (checkIfChina()) {
      return undefined;
    }

    const token = await executeRecaptcha();
    return token;
  };

  // if returns null means it's an error
  const executeRecaptchaV2 = async () => {
    if (checkIfChina()) {
      return undefined;
    }

    if (!showCaptchaV2) {
      return undefined;
    }

    const recaptchaV2Token = captchaRef.current?.getValue();
    if (!recaptchaV2Token) {
      return null;
    }

    return recaptchaV2Token;
  };

  const RecaptchaGoogle = showCaptchaV2 ?
    <Recaptcha
      className='mb-1'
      ref={captchaRef}
      sitekey={RECAPTCHA.V2_SITE_KEY}
    />
    : null;

  return {
    captchaRef,
    executeRecaptchaV2,
    executeRecaptchaV3,
    isExecutingRecaptcha,
    RecaptchaGoogle,
    setShowCaptchaV2,
    showCaptchaV2,
  };
};
