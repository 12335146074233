import 'react-phone-input-2/lib/style.css';
import { useIpLookup } from '../../hooks/ip-lookup';
import style from './LoginPhoneInput.module.scss';
import { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import { notificationsService } from 'services';

type Props = {
  // for e2e tests, so element can be find easily
  className?: string,
  name?: string,
  onChange?: (string_: string) => void,
  value?: string,
};

export const LoginPhoneInput = ({ className = '', name = '', onChange = () => {}, value = '' }: Props) => {
  const [country, setCountry] = useState('us');

  const { data, error, loading } = useIpLookup();

  useEffect(() => {
    if (loading || !data.countryCode) {
      return;
    }

    if (data?.countryCode) {
      setCountry(data.countryCode.toLowerCase());
    }

    if (error) {
      notificationsService.error('Cannot fetch country');
    }
  }, [data, loading]);

  return (
    <div>
      <PhoneInput
        buttonClass={style.button}
        containerClass={[style.loginPhoneInput, className].join('')}
        country={country}
        data-test={name}
        dropdownClass={style.dropdown}
        enableAreaCodes
        enableLongNumbers
        inputClass={style.input}
        inputProps={{ 'data-test': name }}
        onChange={(text) => onChange(text)}
        placeholder='Your phone number'
        value={value}
      />
    </div>
  );
};
